import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  media: {
    padding: 48,
    backgroundColor: '#F4F6F9',
    ...(theme.isDarkMode && ({
      [theme.breakpoints.up('lg')]: {
        backgroundColor: 'inherit',
      },
    })),
  },
  mediaItem: {
    margin: 10,
    minWidth: 140,
    maxWidth: 140,
  },
}));
