import { LogoUrls } from 'apps/logo';
import { get } from 'lodash';
import { BiometricTypes } from 'models/Biometric.model';
import { DocumentTypes } from 'models/Document.model';
import { ProductIntegrationTypes, ProductTypes } from 'models/Product.model';
import { IFacematchFlow } from 'apps/FacematchService';
import { LocationValidation } from 'apps/LocationIntelligence';
import { IVideoAgreementValidation } from 'apps/VideoAgreement';
import { BackgroundCheckTypes } from 'models/BackgroundCheck.model';
import { IVerificationPatterns, VerificationPatternTypes } from 'models/VerificationPatterns.model';
import { IAdvancedLivenessConfig, IBiometricVerification, IDuplicateFaceDetectionSettings } from 'apps/biometricVerification';
import { VerificationRetryBlockerModeTypes } from 'apps/flowBuilder';
import { electronicSignatureFlowInitialState, IESignatureFlow } from './ESignature.model';
import { InputValidationCheck, InputValidationType } from './ImageValidation.model';
import { DigitalSignatureProvider } from './DigitalSignature.model';
import { IFlowWatchlists } from './Watchlist.model';
import { IFlowWatchlist } from './CustomWatchlist.model';
import { IFlowCustomFieldsInputData } from './CustomField.model';
import { VerificationInputTypes } from './VerificationPatternsConfigs.model';
import { CurrenciesTypes } from './Country.model';

export const MAX_NUMBER_OF_FLOWS = 100;

export function getNewFlowId(merchantFlowsModel, currentFlowId) {
  const currentIndex = merchantFlowsModel.value.findIndex((flow) => flow.id === currentFlowId);
  const newIndex = currentIndex ? currentIndex - 1 : currentIndex + 1;
  return get(merchantFlowsModel, `value[${newIndex}].id`, currentFlowId);
}

export function createEmptyFlow(data?: Partial<IFlow>): IFlow {
  return {
    computations: [
      'age',
      'isDocumentExpired',
    ],
    customFieldsConfig: {
      fields: [],
    },
    denyUploadsFromMobileGallery: false,
    digitalSignature: DigitalSignatureProvider.NONE,
    electronicSignature: electronicSignatureFlowInitialState,
    emailRiskThreshold: 80,
    inputValidationChecks: [
      {
        id: InputValidationType.GrayscaleImage,
        isDisabled: true,
      },
      {
        id: InputValidationType.SimilarImages,
        isDisabled: true,
      },
      {
        id: InputValidationType.IdenticalImages,
        isDisabled: true,
      },
      {
        id: InputValidationType.DocumentDetected,
        isDisabled: false,
      },
    ],
    integrationType: ProductIntegrationTypes.Sdk,
    ipValidation: {
      allowedRegions: [],
    },
    style: {
      color: 'blue',
      language: 'en',
    },
    supportedCountries: [],
    verificationPatterns: {
      [VerificationPatternTypes.Biometrics]: BiometricTypes.none,
      [VerificationPatternTypes.BackgroundBrazilianChecks]: BackgroundCheckTypes.None,
    },
    verificationSteps: [],
    ...data,
  };
}

export enum CustomizablePropertiesTypes {
  AccentColor = 'accent-color',
  ErrorColor = 'error-color',
  WarningColor = 'warning-color',
  SuccessColor = 'success-color',
  BackgroundColor = 'background-color',
  BackgroundAltColor = 'background-alt-color',
  BodyTextColor = 'body-text-color',
  TitleTextColor = 'title-text-color',
  SubtitleTextColor = 'subtitle-text-color',
  LabelTextColor = 'label-text-color',
  LineColor = 'line-color',
  BorderColor = 'border-color',
  FontFamily = 'font-family',
}

export enum SDKRedirectionOptionsTypes{
  QR = 'qr',
  Email = 'email',
}

export const DefaultCustomizationColors = {
  [CustomizablePropertiesTypes.AccentColor]: '#507ded',
  [CustomizablePropertiesTypes.ErrorColor]: '#ca4d58',
  [CustomizablePropertiesTypes.WarningColor]: '#ddae44',
  [CustomizablePropertiesTypes.SuccessColor]: '#5AC794',
  [CustomizablePropertiesTypes.BackgroundColor]: '#ffffff',
  [CustomizablePropertiesTypes.BackgroundAltColor]: '#f7f7fa',
  [CustomizablePropertiesTypes.BodyTextColor]: '#232939',
  [CustomizablePropertiesTypes.TitleTextColor]: '#232939',
  [CustomizablePropertiesTypes.SubtitleTextColor]: '#8392b8',
  [CustomizablePropertiesTypes.LabelTextColor]: '#8392B8',
  [CustomizablePropertiesTypes.LineColor]: '#8392B8',
  [CustomizablePropertiesTypes.BorderColor]: '#cbd2e2',
};
export interface ISdkCustomProperty {
  name: string;
  value: string;
}
export interface IAppearance {
  properties: ISdkCustomProperty[];
  isDisplayedPoweredByMetamap: boolean;
}
export type IFlowPhoneOwnership = {
  cooldownTimeout: 'PT1M' | string;
  codeAttemptLimit: number;
  codeSendLimit: number;
  companyName: string;
}

export type IFlowEmailOwnership = {
  companyName: string;
  emailFrom?: string;
}

export type IFlowSystemSettings = {
  useTaskQueueProcessing: boolean;
}

export type VerificationStepsType = (DocumentTypes | string)[][];

export type IFlowComputationTypes = 'age' | 'isDocumentExpired' | string;

export interface ISettings {
  ageCheckOn?: boolean;
  dataSource?: VerificationInputTypes;
  fuzzyNameMatchThreshold?: number;
}

export type PatternSettingsType = {
  [P in VerificationPatternTypes]?: ISettings;
}

interface IThresholds {
  thresholds: [
    {
      currency: CurrenciesTypes;
      value: number;
    }
  ];
}

export interface IFlowFinancialInformationChecks {
  averageBalance?: IThresholds;
  averageExpenses?: IThresholds;
  averageIncome?: IThresholds;
  currentBalance?: IThresholds;
  totalDeposits?: IThresholds;
  totalWithdrawals?: IThresholds;
}

export interface IFlowStyle {
  color?: string;
  language?: string;
}

export type CurrentFlowIdType = string;

export enum IFlowTagTypes {
  CanUseAgeConsistencyCheck = 'can-use-age-consistency-check',
  CanDisableDocumentCroppedCheck = 'can-disable-document-cropped-check',
}

export interface IFlow {
  id?: string;
  // TODO @dkchv: !!!
  _id?: string;
  isFlowAlreadyUsed?: boolean;
  ageThreshold?: number;
  allowDocumentsViaWebCamera?: boolean;
  allowDocumentsViaNativeCamera?: boolean;
  disableCroppedDocumentDetection?: boolean;
  advancedLivenessConfig?: IAdvancedLivenessConfig;
  biometrics?: IBiometricVerification;
  phoneOwnership?: Partial<IFlowPhoneOwnership>;
  phoneRiskAnalysisThreshold?: number;
  emailOwnership?: IFlowEmailOwnership;
  emailRiskThreshold?: number;
  computations?: IFlowComputationTypes[];
  createdAt?: string;
  denyUploadsFromMobileGallery?: boolean;
  digitalSignature?: DigitalSignatureProvider;
  disableAlterationDetection?: boolean;
  disableDocumentReading?: boolean;
  countriesSettings?: [{
    country: String;
    documentSubtypes: [String];
  }];
  facematchThreshold?: number;
  reFacematchThreshold?: number;
  ipValidation?: LocationValidation;
  inputTypes?: { id?: string }[];
  inputValidationChecks?: InputValidationCheck[];
  isDuplicateFaceRejection?: boolean;
  isDuplicateUserRejection?: boolean;
  logo?: LogoUrls;
  name?: string;
  policyInterval?: string;
  postponedTimeout?: string;
  pinnedCountries?: string[];
  style?: IFlowStyle;
  customFieldsConfig?: IFlowCustomFieldsInputData;
  supportedCountries?: string[];
  updatedAt?: string;
  verificationSteps?: VerificationStepsType;
  verificationPatterns?: Partial<IVerificationPatterns>;
  integrationType?: ProductIntegrationTypes;
  customWatchlists?: IFlowWatchlist[];
  watchlists?: IFlowWatchlists;
  videoAgreement?: IVideoAgreementValidation;
  electronicSignature?: IESignatureFlow;
  financialInformationBankAccountsRetrieving?: {
    countryCodes: string[];
    checks?: IFlowFinancialInformationChecks;
  };
  financialInformationBankStatementParsingRetrieving?: {
    countryCodes: string[];
    checks?: IFlowFinancialInformationChecks;
    validationMonth: number;
    validationRecency: number;
  };
  facematchServiceConfig?: IFacematchFlow;
  duplicateFaceMatchThreshold?: number;
  postResultPhaseTimeout?: string;
  patternSettings?: PatternSettingsType;
  productSettings?: {
    [P in ProductTypes]?: ISettings;
  };
  appearance?: IAppearance;
  redirectOptions?: string[];
  allowedRedirectionUrls?: string[];
  tags?: IFlowTagTypes[];
  retryBlockerConfig?: {
    mode: VerificationRetryBlockerModeTypes;
    timeout?: string;
    primaryKey?: string;
  };
  isLivenessRejection?: boolean;
  duplicateFaceDetectionSettings?: IDuplicateFaceDetectionSettings;
}
