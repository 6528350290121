import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useFreemium } from 'apps/Freemiun';
import { appPalette } from 'apps/theme';
import { BoxBordered, Warning, WarningSize, WarningTypes, RadioButton, ExtendedDescription, FlowError, Badge } from 'apps/ui';
import livenessDemoImage from 'assets/livenessDemo.png';
import livenessDemoVideo from 'assets/livenessDemo.mp4';
import { useSelector } from 'react-redux';
import { useMerchantPlan } from 'apps/merchant';
import { MerchantTagsTypes } from 'models/Merchant.model';
import { selectMerchantTags } from 'state/merchant/merchant.selectors';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useFormatMessage } from 'apps/intl';
import { BiometricTypes } from 'models/Biometric.model';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import { ReactComponent as CheckboxOn } from 'assets/icon-checkbox-on.svg';
import { ReactComponent as CheckboxOff } from 'assets/icon-checkbox-off.svg';
import { capitalizeFirstLetter } from 'lib/client/words';
import { getVerificationType, getBiometricType, BiometricVerificationTypes, BiometricVerificationThresholdOptionTypes, BiometricVerificationThresholdErrorTypes, hasDuplicateFaceDetectionThresholdError, MIN_DUPLICATE_FACE_DETECTION_THRESHOLD, MAX_DUPLICATE_FACE_DETECTION_THRESHOLD, MAX_DUPLICATE_FACE_DETECTION_THRESHOLD_FRACTION, getDuplicateFaceDetectionThresholdOptionType, DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD, BiometricDuplicateUserDetectionFlowTypes, BiometricVerificationNistInputTypes, BiometricVerificationChallengeTypes, BiometricVerificationNistProviderTypes, IBiometricVerification, LivenessRejectionTypes, VerificationStatusFilterTypes, VerificationStatusFilter, IVerificationStatusFilterStatus, IAdvancedLivenessConfig } from '../../models/BiometricVerification.model';
import { useStyles } from './BiometricConfiguration.styles';
import { BetaBadge, NewBadge } from '../Badge/Badge';
import { CertifiedBadges } from '../CertifiedBadges/CertifiedBadges';

const LIVENESS_DOCS_LINK = 'https://docs.metamap.com/docs/biometric-verification-webhooks#static-image-verification-selfie';
const CAPTURE_DOCS_LINK = 'https://docs.metamap.com/docs/metamap-button#auto-capture-experience';
const FRAUD_DOCS_LINK = 'https://docs.metamap.com/docs/biometrics#advanced-configurations';

export function BiometricConfiguration({ duplicateFaceDetection, duplicateFaceDetectionThreshold = DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD, biometrics, proofOfOwnership, isDuplicateFaceRejection, isReVerification, onUpdate, onThresholdChange, onPatternsChange, onDuplicateUserFlowStatusChange, canUseAgeConsistencyCheck, onAgeConsistencyCheckToggle, isAgeConsistencyCheckEnabled, sdkError, isLivenessRejection, onLivenessRejectionChange, duplicateFaceDetectionSettingsError, duplicateFaceDetectionSettings, onDuplicateFaceDetectionSettingsChange, advancedLivenessConfiguration, onChangeAdvancedLivenessConfiguration }: {
  biometrics: Nullable<string>;
  proofOfOwnership: boolean;
  onUpdate: (biometrics: Nullable<string>, biometricVerificationPattern?: boolean, biometricsVerification?: Nullable<IBiometricVerification>) => void;
  duplicateFaceDetection?: boolean;
  duplicateFaceDetectionThreshold?: number;
  isDuplicateFaceRejection?: boolean;
  isReVerification?: boolean;
  canUseAgeConsistencyCheck?: boolean;
  isAgeConsistencyCheckEnabled?: boolean;
  onThresholdChange?: (threshold: Nullable<number>) => void;
  onPatternsChange?: () => void;
  onDuplicateUserFlowStatusChange?: (status: boolean) => void;
  onAgeConsistencyCheckToggle?: () => void;
  sdkError?: boolean;
  isLivenessRejection?: boolean;
  onLivenessRejectionChange?: (updatedValue: boolean) => void;
  duplicateFaceDetectionSettingsError?: boolean;
  duplicateFaceDetectionSettings?: IVerificationStatusFilterStatus[];
  onDuplicateFaceDetectionSettingsChange?: (updatedValue: IVerificationStatusFilterStatus[]) => void;
  advancedLivenessConfiguration?: IAdvancedLivenessConfig;
  onChangeAdvancedLivenessConfiguration?: (updatedValue: IAdvancedLivenessConfig) => void;
}) {
  const classes = useStyles();
  const formatMessage = useFormatMessage();
  const { upgrade } = useFreemium();
  const { isFreemium } = useMerchantPlan();
  const [score, setScore] = useState<Nullable<string>>(duplicateFaceDetectionThreshold?.toString() || null);
  const [thresholdOption, setThresholdOption] = useState<BiometricVerificationThresholdOptionTypes>(duplicateFaceDetectionThreshold === DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD ? BiometricVerificationThresholdOptionTypes.RecommendedThreshold : BiometricVerificationThresholdOptionTypes.CustomThreshold);
  const [duplicateUserDetectionFlowStatus, setDuplicateUserDetectionFlowStatus] = useState<BiometricDuplicateUserDetectionFlowTypes>(!isDuplicateFaceRejection ? BiometricDuplicateUserDetectionFlowTypes.ManualReview : BiometricDuplicateUserDetectionFlowTypes.Rejected);
  const [thresholdError, setThresholdError] = useState<Nullable<BiometricVerificationThresholdErrorTypes>>(null);
  const merchantTags = useSelector<any, MerchantTagsTypes[]>(selectMerchantTags);
  const [duplicateFaceSetting, setDuplicateFaceSettings] = useState<IVerificationStatusFilterStatus[]>([]);
  const isDuplicateFaceDetectionEnabled = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseDuplicateFaceDetection)), [merchantTags]);
  const isActiveLivessEnabled = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseActiveLivenss)), [merchantTags]);
  const isPassiveLivessEnabled = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUsePassiveLivenss)), [merchantTags]);
  const isIncodePassiveLivenessEnabled = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.ShouldUseIncodePassiveLiveness)), [merchantTags]);
  const isDigitalSpoofAlloved = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseDigitalSpoofValidation)), [merchantTags]);
  const isFaceEvasionAlloved = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseFaceEvasionValidation)), [merchantTags]);
  const isAgeCheckAlloved = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanUseAgeCheckSelfie)), [merchantTags]);
  const isVideoDisabled = useMemo<boolean>(() => (merchantTags.includes(MerchantTagsTypes.CanNotUseSelfieVideo)), [merchantTags]);
  const [intialValueOfDuplicateFaceSetting, setInitalValueOfDuplicateFaceSetting] = useState<Nullable<IVerificationStatusFilterStatus[]>>();

  const { verificationType } = useMemo<{
    verificationType: BiometricVerificationTypes;
  }>(
    () => ({
      verificationType: getVerificationType(biometrics),
    }),
    [biometrics],
  );

  useEffect(() => {
    setInitalValueOfDuplicateFaceSetting(duplicateFaceDetectionSettings);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleVerificationTypeChange = useCallback(({ target: { value } }) => {
    const biometricType = getBiometricType(value);
    if ([BiometricVerificationTypes.LivenessNistActive, BiometricVerificationTypes.LivenessNistPassive].includes(value)) {
      onUpdate(BiometricTypes.none, true, {
        input: BiometricVerificationNistInputTypes.LivenessNist,
        challenge: biometricType === BiometricVerificationTypes.LivenessNistActive ? BiometricVerificationChallengeTypes.ACTIVE : BiometricVerificationChallengeTypes.PASSIVE,
        provider: BiometricVerificationNistProviderTypes.IDEMIA,
      });
    } else {
      onUpdate(biometricType);
    }
  }, [onUpdate]);

  const handleScoreChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (thresholdError) {
      setThresholdError(null);
    }
    const [integer, fraction] = event.target.value.split('.');
    let result = integer;
    if (fraction) {
      result += `.${fraction.slice(0, MAX_DUPLICATE_FACE_DETECTION_THRESHOLD_FRACTION)}`;
    }
    setScore(result);
  }, [thresholdError]);

  const handleValidation = useCallback((threshold: number) => () => {
    if (hasDuplicateFaceDetectionThresholdError(threshold)) {
      setThresholdError(BiometricVerificationThresholdErrorTypes.OutOfRange);
    }
    onThresholdChange(threshold);
  }, [onThresholdChange]);

  const handleDuplicateFaceDetectionOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const thresholdOptionValue = event.target.value;
    setThresholdOption(getDuplicateFaceDetectionThresholdOptionType(thresholdOptionValue));
    if (thresholdOptionValue === BiometricVerificationThresholdOptionTypes.RecommendedThreshold) {
      setThresholdError(null);
      setScore(DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD.toString());
      onThresholdChange(DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD);
    }
  };

  const handleDuplicateUserDetectionFlowStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onDuplicateUserFlowStatusChange) {
      const updatedDuplicateUserDetectionFlowStatus = event.target.value as BiometricDuplicateUserDetectionFlowTypes;
      setDuplicateUserDetectionFlowStatus(updatedDuplicateUserDetectionFlowStatus);
      onDuplicateUserFlowStatusChange(updatedDuplicateUserDetectionFlowStatus === BiometricDuplicateUserDetectionFlowTypes.Rejected);
    }
  };

  const handleLivenessRejectionStatusChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isManualReview = event.target.value === LivenessRejectionTypes.ManualReview;
    onLivenessRejectionChange(!isManualReview);
  };

  const onDuplicateDetectionSettingsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!duplicateFaceSetting) {
      return;
    }

    const updatedDuplicateFaceSetting = [...duplicateFaceSetting];
    const itemIndex = updatedDuplicateFaceSetting.findIndex((item) => item.status === event.target.name);
    const manualStatusIndex = intialValueOfDuplicateFaceSetting?.findIndex((item) => item.status === event.target.name);

    let updatedManualStatus = true;

    if (manualStatusIndex !== -1) {
      updatedManualStatus = intialValueOfDuplicateFaceSetting[manualStatusIndex]?.manualChange ?? true;
    }

    if (itemIndex !== -1) {
      // Uncheck scenario
      updatedDuplicateFaceSetting.splice(itemIndex, 1);
    } else {
      updatedDuplicateFaceSetting.push({
        status: event.target.name as VerificationStatusFilterTypes,
        manualChange: updatedManualStatus,
      });
    }
    onDuplicateFaceDetectionSettingsChange(updatedDuplicateFaceSetting);
  };

  useEffect(() => {
    setDuplicateFaceSettings(duplicateFaceDetectionSettings);
  }, [duplicateFaceDetectionSettings]);

  const getChangeStatus = useCallback((status: VerificationStatusFilterTypes): boolean => {
    const item = duplicateFaceSetting.find((data: { status: VerificationStatusFilterTypes }) => data.status === status);
    return !!item;
  }, [duplicateFaceSetting]);

  return (
    <Box className={classes.root}>
      {(isActiveLivessEnabled || isPassiveLivessEnabled) && sdkError && [BiometricVerificationTypes.LivenessNistActive, BiometricVerificationTypes.LivenessNistPassive].includes(verificationType) && (
        <Box width="100%" mb={2.4} mt={2.4}>
          {verificationType === BiometricVerificationTypes.LivenessNistActive ? <FlowError text={formatMessage('Biometrics.setting.nistLivenessActive.warning')} /> : <FlowError text={formatMessage('Biometrics.setting.nistLivenessPassive.warning')} />}
        </Box>
      )}
      <RadioGroup
        aria-label="biometric-step"
        name="biometric-steps"
        value={verificationType}
        onChange={handleVerificationTypeChange}
      >
        {isIncodePassiveLivenessEnabled && (
          <BoxBordered mb={2.4}>
            <FormControlLabel
              value={BiometricVerificationTypes.SelfiePhoto}
              control={<RadioButton color="primary" />}
              label={(
                <Grid container wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
                  <Box>
                    <Box mb={0.5}>
                      <Box color="common.black90" fontWeight="bold" display="flex" flexDirection="row" alignItems="center" pb={0.5} mt={-0.3}>
                        {formatMessage('Biometrics.setting.livenessPassive.title')}
                        <NewBadge />
                      </Box>
                      <Box mt={0.5} display="flex" flexDirection="row" alignItems="center">
                        <Badge text={formatMessage('Biometrics.setting.badge.iBetaCertified')} />
                        <Badge text={formatMessage('Biometrics.setting.badge.poweredByIncode')} />
                      </Box>
                    </Box>
                    <Box color="common.black75" lineHeight={1.2}>
                      {formatMessage('Biometrics.setting.livenessPassive.description.liveness', {
                        messageValues: {
                          livenessDocs: (
                            <a
                              href={LIVENESS_DOCS_LINK}
                              className={classes.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {formatMessage('Biometrics.setting.livenessPassive.description.livenessDocs')}
                            </a>
                          ),
                        },
                      })}
                    </Box>
                    <Box mt={1.5} color="common.black75" lineHeight={1.2}>
                      {formatMessage('Biometrics.setting.livenessPassive.description.capture', {
                        messageValues: {
                          captureDocs: (
                            <a
                              href={CAPTURE_DOCS_LINK}
                              className={classes.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {formatMessage('Biometrics.setting.livenessPassive.description.captureDocs')}
                            </a>
                          ),
                        },
                      })}
                    </Box>
                    <Box mt={1.5}>
                      <Box color="common.black90" fontWeight="bold" display="flex" flexDirection="row" alignItems="center" pb={0.5} mt={-0.3}>
                        {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.title')}
                      </Box>
                      <Box mt={0.5} color="common.black75" lineHeight={1.2}>
                        {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.description', {
                          messageValues: {
                            fraudDocs: (
                              <a
                                href={FRAUD_DOCS_LINK}
                                className={classes.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.description.fraudDocs')}
                              </a>
                            ),
                          },
                        })}
                      </Box>
                      <BoxBordered mt={1}>
                        <Box>
                          <Grid container alignItems="flex-start" justifyContent="flex-start">
                            <Grid item>
                              <Checkbox
                                disabled={!isDigitalSpoofAlloved || (verificationType !== BiometricVerificationTypes.SelfiePhoto)}
                                color="primary"
                                checked={advancedLivenessConfiguration.isDigitalSpoofEnabled}
                                onChange={() => onChangeAdvancedLivenessConfiguration({
                                  ...advancedLivenessConfiguration,
                                  isDigitalSpoofEnabled: !advancedLivenessConfiguration.isDigitalSpoofEnabled,
                                })}
                                checkedIcon={<CheckboxOn />}
                                icon={<CheckboxOff />}
                              />
                            </Grid>
                            <Grid item>
                              <Box mt={1} color="common.black75" lineHeight={1.2}>
                                {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.digitalSpoof')}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={0.5} color="common.black75" lineHeight={1.2}>
                          {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.digitalSpoof.description')}
                        </Box>
                        {!isDigitalSpoofAlloved && (
                          <Box mt={1}>
                            <Warning
                              filled
                              isLabelColored
                              align="center"
                              className={classes.upgrade}
                              type={WarningTypes.Upgrade}
                              label={formatMessage('Biometrics.setting.livenessPassive.upgrade', {
                                messageValues: {
                                  link: (
                                    <span
                                      tabIndex={0}
                                      role="button"
                                      onKeyDown={() => upgrade()}
                                      onClick={() => upgrade()}
                                      className={classes.link}
                                    >
                                      {formatMessage('Biometrics.setting.livenessPassive.upgrade.link')}
                                    </span>
                                  ),
                                },
                              })}
                              bordered
                            />
                          </Box>
                        )}
                      </BoxBordered>
                      <BoxBordered mt={1}>
                        <Box>
                          <Grid container alignItems="flex-start" justifyContent="flex-start">
                            <Grid item>
                              <Checkbox
                                disabled={!isFaceEvasionAlloved || (verificationType !== BiometricVerificationTypes.SelfiePhoto)}
                                color="primary"
                                checked={advancedLivenessConfiguration.isFaceEvasionEnabled}
                                onChange={() => onChangeAdvancedLivenessConfiguration({
                                  ...advancedLivenessConfiguration,
                                  isFaceEvasionEnabled: !advancedLivenessConfiguration.isFaceEvasionEnabled,
                                })}
                                checkedIcon={<CheckboxOn />}
                                icon={<CheckboxOff />}
                              />
                            </Grid>
                            <Grid item>
                              <Box mt={1} color="common.black75" lineHeight={1.2}>
                                {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.faceEvasion')}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={0.5} color="common.black75" lineHeight={1.2}>
                          {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.faceEvasion.description')}
                        </Box>
                        {!isFaceEvasionAlloved && (
                          <Box mt={1}>
                            <Warning
                              filled
                              isLabelColored
                              align="center"
                              className={classes.upgrade}
                              type={WarningTypes.Upgrade}
                              label={formatMessage('Biometrics.setting.livenessPassive.upgrade', {
                                messageValues: {
                                  link: (
                                    <span
                                      tabIndex={0}
                                      role="button"
                                      onKeyDown={() => upgrade()}
                                      onClick={() => upgrade()}
                                      className={classes.link}
                                    >
                                      {formatMessage('Biometrics.setting.livenessPassive.upgrade.link')}
                                    </span>
                                  ),
                                },
                              })}
                              bordered
                            />
                          </Box>
                        )}
                      </BoxBordered>
                      <BoxBordered mt={1}>
                        <Box>
                          <Grid container alignItems="flex-start" justifyContent="flex-start">
                            <Grid item>
                              <Checkbox
                                disabled={!isAgeCheckAlloved || (verificationType !== BiometricVerificationTypes.SelfiePhoto)}
                                color="primary"
                                checked={advancedLivenessConfiguration.isAgeVerificationEnabled}
                                onChange={() => onChangeAdvancedLivenessConfiguration({
                                  ...advancedLivenessConfiguration,
                                  isAgeVerificationEnabled: !advancedLivenessConfiguration.isAgeVerificationEnabled,
                                  ageVerificationThreshold: !advancedLivenessConfiguration.isAgeVerificationEnabled ? 18 : undefined,
                                })}
                                checkedIcon={<CheckboxOn />}
                                icon={<CheckboxOff />}
                              />
                            </Grid>
                            <Grid item>
                              <Box mt={1} color="common.black75" lineHeight={1.2}>
                                {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.ageVerification')}
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box mt={0.5} color="common.black75" lineHeight={1.2}>
                          {formatMessage('Biometrics.setting.livenessPassive.advancedConfiguration.ageVerification.description')}
                        </Box>
                        <Box mt={0.5}>
                          <TextField
                            type="number"
                            disabled={!isAgeCheckAlloved || (verificationType !== BiometricVerificationTypes.SelfiePhoto) || !advancedLivenessConfiguration.isAgeVerificationEnabled}
                            value={advancedLivenessConfiguration.isAgeVerificationEnabled ? advancedLivenessConfiguration.ageVerificationThreshold : 18}
                            variant="outlined"
                            style={{ width: 80 }}
                            onChange={({ target: { value } }) => {
                              const parsed = value ? parseInt(value, 10) : undefined;
                              onChangeAdvancedLivenessConfiguration({
                                ...advancedLivenessConfiguration,
                                ageVerificationThreshold: (parsed < 0) ? 0 : parsed,
                              });
                            }}
                          />
                        </Box>
                        {!isAgeCheckAlloved && (
                          <Box mt={1}>
                            <Warning
                              filled
                              isLabelColored
                              align="center"
                              className={classes.upgrade}
                              type={WarningTypes.Upgrade}
                              label={formatMessage('Biometrics.setting.livenessPassive.upgrade', {
                                messageValues: {
                                  link: (
                                    <span
                                      tabIndex={0}
                                      role="button"
                                      onKeyDown={() => upgrade()}
                                      onClick={() => upgrade()}
                                      className={classes.link}
                                    >
                                      {formatMessage('Biometrics.setting.livenessPassive.upgrade.link')}
                                    </span>
                                  ),
                                },
                              })}
                              bordered
                            />
                          </Box>
                        )}
                      </BoxBordered>
                    </Box>
                  </Box>
                </Grid>
              )}
            />
          </BoxBordered>
        )}
        <BoxBordered mb={2.4}>
          <FormControlLabel
            value={BiometricVerificationTypes.SelfieVideo}
            control={<RadioButton color="primary" />}
            disabled={isVideoDisabled}
            label={(
              <Box>
                <Grid container wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
                  <Box>
                    <Box mb={0.5} color="common.black90" fontWeight="bold">
                      {formatMessage('Biometrics.settings.selfieVideo')}
                    </Box>
                    <Box color="common.black75" lineHeight={1.2}>
                      {formatMessage('Biometrics.settings.selfieVideo.description')}
                    </Box>
                  </Box>
                  <Box ml={2.4} className={classes.media}>
                    <video muted autoPlay playsInline loop src={livenessDemoVideo} />
                  </Box>
                </Grid>
                {/* Liveness Rejection Setting */}
                {!isReVerification && verificationType === BiometricVerificationTypes.SelfieVideo && (
                  <Box mt={2.4}>
                    <Box>
                      <ExtendedDescription
                        title={formatMessage('Biometrics.setting.livenessRejection.title')}
                      />
                      <Box mt={1}>
                        <RadioGroup
                          aria-label="duplicate-user-detection-rejection-type"
                          name="duplicate-user-detection-rejection-type"
                          value={isLivenessRejection ? LivenessRejectionTypes.Rejected : LivenessRejectionTypes.ManualReview}
                          onChange={handleLivenessRejectionStatusChange}
                        >
                          <>
                            <Box m={1}>
                              <FormControlLabel
                                value={LivenessRejectionTypes.ManualReview}
                                control={<RadioButton color="primary" />}
                                label={(
                                  <Box mb={0.5} color="common.black90" fontWeight="bold">
                                    {formatMessage('DocumentVerification.settings.duplicateUserDetection.flowStatus.ManualReview')}
                                  </Box>
                                )}
                              />
                            </Box>
                            <Box m={1}>
                              <FormControlLabel
                                value={LivenessRejectionTypes.Rejected}
                                control={<RadioButton color="primary" />}
                                label={(
                                  <Box mb={0.5} color="common.black90" fontWeight="bold">
                                    {formatMessage('DocumentVerification.settings.duplicateUserDetection.flowStatus.Rejected')}
                                  </Box>
                                )}
                              />
                            </Box>
                          </>
                        </RadioGroup>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
          />
        </BoxBordered>
        {isActiveLivessEnabled && (
          <BoxBordered mb={2.4}>
            <FormControlLabel
              value={BiometricVerificationTypes.LivenessNistActive}
              control={<RadioButton color="primary" />}
              disabled={proofOfOwnership}
              label={(
                <Grid container wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
                  <Box>
                    <Box mb={0.5}>
                      <Box color="common.black90" fontWeight="bold" display="flex" flexDirection="row" alignItems="center" pb={0.5} mt={-0.3}>
                        {formatMessage('Biometrics.setting.nistLivenessActive.title')}
                        <BetaBadge />
                      </Box>
                      <CertifiedBadges />
                    </Box>
                    <Box color="common.black75" lineHeight={1.2}>
                      {formatMessage('Biometrics.setting.nistLivenessActive.description')}
                    </Box>
                    {proofOfOwnership && (
                      <BoxBordered borderColor={appPalette.yellow} mt={2.4}>
                        <Warning
                          type={WarningTypes.Warning}
                          size={WarningSize.Large}
                          label={formatMessage('Biometrics.settings.proofOfOwnerwship.nistLivenessActive.warning')}
                        />
                      </BoxBordered>
                    )}
                    <Box mt={1}>
                      <Warning
                        type={WarningTypes.Warning}
                        label={formatMessage('Biometrics.settings.activeLiveness.deprecation')}
                        isLabelColored={false}
                        bordered
                      />
                    </Box>
                  </Box>
                </Grid>
              )}
            />
          </BoxBordered>
        )}
        {!isPassiveLivessEnabled && (isFreemium ? (
          <Warning
            label={formatMessage('Freemium.productNotAvailable')}
            isLabelColored={false}
            bordered
          />
        ) : (
          <Warning
            label={formatMessage('Biometrics.setting.nistLivenessPassive.notAvailable')}
            linkLabel={formatMessage('Biometrics.setting.nistLivenessPassive.helpEmail')}
            isLabelColored={false}
            meritName={formatMessage('Biometrics.setting.nistLivenessPassive.title')}
            bordered
          />
        ))}
        <BoxBordered mb={2.4}>
          <FormControlLabel
            value={BiometricVerificationTypes.LivenessNistPassive}
            control={<RadioButton color="primary" />}
            disabled={proofOfOwnership || !isPassiveLivessEnabled}
            label={(
              <Grid container wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
                <Box>
                  <Box mb={0.5}>
                    <Box color="common.black90" fontWeight="bold" display="flex" flexDirection="row" alignItems="center" pb={0.5} mt={-0.3}>
                      {formatMessage('Biometrics.setting.nistLivenessPassive.title')}
                      <BetaBadge />
                    </Box>
                    <CertifiedBadges />
                  </Box>
                  <Box color="common.black75" lineHeight={1.2}>
                    {formatMessage('Biometrics.setting.nistLivenessPassive.description')}
                  </Box>
                  {proofOfOwnership && (
                    <BoxBordered borderColor={appPalette.yellow} mt={1}>
                      <Warning
                        type={WarningTypes.Warning}
                        size={WarningSize.Large}
                        label={formatMessage('Biometrics.settings.proofOfOwnerwship.nistLivenessPassive.warning')}
                      />
                    </BoxBordered>
                  )}
                  <Box mt={1}>
                    <Warning
                      type={WarningTypes.Warning}
                      label={formatMessage('Biometrics.settings.passiveLiveness.deprecation')}
                      isLabelColored={false}
                      bordered
                    />
                  </Box>
                </Box>
              </Grid>
            )}
          />
        </BoxBordered>
        {isFreemium && (
          <Warning
            label={formatMessage('Freemium.productNotAvailable')}
            isLabelColored={false}
            bordered
          />
        )}
        {!isIncodePassiveLivenessEnabled && (
          <BoxBordered mb={2.4}>
            <FormControlLabel
              value={BiometricVerificationTypes.SelfiePhoto}
              control={<RadioButton color="primary" />}
              label={(
                <Grid container wrap="nowrap" alignItems="flex-start" justifyContent="space-between">
                  <Box>
                    <Box mb={0.5} color="common.black90" fontWeight="bold">
                      {formatMessage('Biometrics.settings.selfiePhoto')}
                    </Box>
                    <Box color="common.black75" lineHeight={1.2}>
                      {formatMessage('Biometrics.settings.selfiePhoto.description')}
                    </Box>
                  </Box>
                  <Box ml={2.4} className={classes.media}>
                    <img alt="" src={livenessDemoImage} />
                  </Box>
                </Grid>
              )}
            />
          </BoxBordered>
        )}
      </RadioGroup>
      {canUseAgeConsistencyCheck && (
        <Box mb={2.4} mt={2.4}>
          <ExtendedDescription
            title={formatMessage('Biometrics.setting.ageConsistencyCheck.title')}
            prefix={(
              <Switch
                checked={isAgeConsistencyCheckEnabled}
                onChange={onAgeConsistencyCheckToggle}
                color="primary"
              />
            )}
          />
        </Box>
      )}

      {!isReVerification && isDuplicateFaceDetectionEnabled && duplicateFaceDetectionSettings && (
        <Box mt={4} mb={4}>
          <ExtendedDescription
            title={formatMessage('Biometrics.settings.duplicateFaceDetection.title')}
            info={formatMessage('Biometrics.settings.duplicateFaceDetection.description')}
            prefix={(
              <Switch
                checked={duplicateFaceDetection}
                onClick={onPatternsChange}
                color="primary"
              />
            )}
          />
          {duplicateFaceDetection && (
            <>
              <BoxBordered mt={1}>
                <Box mb={2} color="common.black75">
                  {formatMessage('Biometrics.settings.duplicateUserDetection.flowStatusChange.title')}
                </Box>
                <Box m={1}>
                  <FormControl>
                    <FormGroup>
                      {VerificationStatusFilter.map((status) => (
                        <FormControlLabel
                          key={status}
                          control={(
                            <Checkbox
                              color="primary"
                              checked={getChangeStatus(status)}
                              onChange={onDuplicateDetectionSettingsChange}
                              name={status}
                              checkedIcon={<CheckboxOn />}
                              icon={<CheckboxOff />}
                            />
                      )}
                          label={(
                            <Box color="common.black90" fontWeight="bold">
                              {formatMessage(`Biometrics.settings.duplicateUserDetection.flowStatus.${capitalizeFirstLetter(status)}`)}
                            </Box>
                      )}
                          style={{ alignItems: 'center' }}
                        />
                      ))}
                      {duplicateFaceDetectionSettingsError && <Box color="common.red">{formatMessage('Biometrics.settings.duplicateUserDetection.flowStatus.helpText')}</Box>}
                    </FormGroup>
                  </FormControl>
                </Box>
              </BoxBordered>
              <RadioGroup
                aria-label="duplicate-user-detection-step"
                name="duplicate-user-detection-steps"
                value={thresholdOption}
                onChange={handleDuplicateFaceDetectionOptionChange}
              >
                <BoxBordered mt={2}>
                  <FormControlLabel
                    disabled={!duplicateFaceDetection}
                    value={BiometricVerificationThresholdOptionTypes.RecommendedThreshold}
                    control={<RadioButton color="primary" />}
                    label={(
                      <Box>
                        <Grid container wrap="nowrap" alignItems="flex-start">
                          <Box>
                            <Box mb={0.5} color="common.black90" fontWeight="bold">
                              {formatMessage('Biometrics.settings.duplicateUserDetection.recommendedThreshold.title', { messageValues: { percentage: DEFAULT_DUPLICATE_FACE_DETECTION_THRESHOLD } })}
                            </Box>
                            <Box color="common.black75" lineHeight={1.2}>
                              {formatMessage('Biometrics.settings.duplicateUserDetection.recommendedThreshold.description')}
                            </Box>
                          </Box>
                        </Grid>
                      </Box>
                    )}
                  />
                </BoxBordered>

                <BoxBordered mt={2}>
                  <FormControlLabel
                    value={BiometricVerificationThresholdOptionTypes.CustomThreshold}
                    disabled={!duplicateFaceDetection}
                    control={<RadioButton color="primary" />}
                    label={(
                      <>
                        <Box mb={1} color="common.black90" fontWeight="bold">
                          {formatMessage('Biometrics.settings.duplicateUserDetection.threshold.title')}
                        </Box>
                        <Box color="common.black75" lineHeight={1.2}>
                          {formatMessage('Biometrics.settings.duplicateUserDetection.threshold.description')}
                        </Box>
                        <TextField
                          disabled={!duplicateFaceDetection}
                          type="number"
                          variant="outlined"
                          value={score}
                          onChange={handleScoreChange}
                          onBlur={handleValidation(parseFloat(score))}
                          helperText={thresholdError && formatMessage(`Biometrics.settings.duplicateUserDetection.threshold.error.${thresholdError}`)}
                          placeholder={`${MIN_DUPLICATE_FACE_DETECTION_THRESHOLD}-${MAX_DUPLICATE_FACE_DETECTION_THRESHOLD}`}
                          error={!!thresholdError}
                        />
                      </>
                    )}
                  />

                </BoxBordered>
              </RadioGroup>
              <RadioGroup
                aria-label="duplicate-user-detection-status"
                name="duplicate-user-detection-status"
                value={duplicateUserDetectionFlowStatus}
                onChange={handleDuplicateUserDetectionFlowStatusChange}
              >
                <BoxBordered mt={1}>
                  <Box mb={2} color="common.black75">
                    {formatMessage('Biometrics.settings.duplicateUserDetection.flowStatus.title')}
                  </Box>
                  <Box m={1}>
                    <FormControlLabel
                      value={BiometricDuplicateUserDetectionFlowTypes.ManualReview}
                      control={<RadioButton color="primary" />}
                      label={(
                        <Box mb={0.5} color="common.black90" fontWeight="bold">
                          {formatMessage('Biometrics.settings.duplicateUserDetection.flowStatus.ManualReview')}
                        </Box>
                      )}
                    />
                  </Box>
                  <Box m={1}>
                    <FormControlLabel
                      value={BiometricDuplicateUserDetectionFlowTypes.Rejected}
                      control={<RadioButton color="primary" />}
                      label={(
                        <Box mb={0.5} color="common.black90" fontWeight="bold">
                          {formatMessage('Biometrics.settings.duplicateUserDetection.flowStatus.Rejected')}
                        </Box>
                      )}
                    />
                  </Box>
                </BoxBordered>
              </RadioGroup>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
