import { Box, Grid, Typography } from '@material-ui/core';
import { BiometricStep, BiometricTypes, getBiometricCheckStatus, IDuplicateSelfieStepData, LivenessStepStatus } from 'models/Biometric.model';
import { BoxBordered, CheckResultLogo } from 'apps/ui';
import { IStep, PremiumLivenessStepTypes, StepTypes } from 'models/Step.model';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { DuplicateSelfieCheck } from 'apps/checks/components/DuplicateSelfieCheck/DuplicateSelfieCheck';
import { MediaStatusTypes } from 'apps/media';
import { LivenessMedia } from '../LivenessMedia/LivenessMedia';
import { useStyles } from './NewLivenessStep.styles';
import { LivenessConslusion } from '../LivenessConslusion/LivenessConslusion';
import { LivenessPremium } from '../LivenessPremium/LivenessPremium';

export function NewLivenessStep({ duplicateFaceDetection, steps = [], downloadableFileName, isAgeVerificationEnabled, isDigitalSpoofEnabled, isFaceEvasionEnabled }: {
  duplicateFaceDetection: IStep<IDuplicateSelfieStepData>;
  steps: BiometricStep[];
  downloadableFileName: string;
  isAgeVerificationEnabled?: boolean;
  isDigitalSpoofEnabled?: boolean;
  isFaceEvasionEnabled?: boolean;
}) {
  const intl = useIntl();
  const classes = useStyles();
  const checkStatus = getBiometricCheckStatus(steps);
  const premiumCheckSteps = useMemo(() => {
    const premiumSteps: BiometricStep[] = [];

    const selfieStep = steps.find((step) => step.id === BiometricTypes.selfie);
    if (selfieStep) {
      if (selfieStep.error) {
        let id;
        if (selfieStep.error.code.includes('digitalSpoof')) {
          id = PremiumLivenessStepTypes.DigitalSpoof;
        }
        if (selfieStep.error.code.includes('faceEvasion')) {
          id = PremiumLivenessStepTypes.FaceEvasion;
        }
        if (selfieStep.error.code.includes('underage')) {
          id = PremiumLivenessStepTypes.AgeVerification;
        }
        premiumSteps.push({
          id,
          checkStatus: selfieStep.checkStatus,
          error: selfieStep.error,
          data: selfieStep.data,
          status: selfieStep.status,
          connectedDocumentType: undefined,
        });
      } else {
        if (isAgeVerificationEnabled) {
          premiumSteps.push({
            id: PremiumLivenessStepTypes.AgeVerification,
            checkStatus: selfieStep.checkStatus,
            error: undefined,
            data: selfieStep.data,
            status: selfieStep.status,
            connectedDocumentType: undefined,
          });
        }
        if (isDigitalSpoofEnabled) {
          premiumSteps.push({
            id: PremiumLivenessStepTypes.DigitalSpoof,
            checkStatus: selfieStep.checkStatus,
            error: undefined,
            data: selfieStep.data,
            status: selfieStep.status,
            connectedDocumentType: undefined,
          });
        }
        if (isFaceEvasionEnabled) {
          premiumSteps.push({
            id: PremiumLivenessStepTypes.FaceEvasion,
            checkStatus: selfieStep.checkStatus,
            error: undefined,
            data: selfieStep.data,
            status: selfieStep.status,
            connectedDocumentType: undefined,
          });
        }
      }
    }

    return premiumSteps;
  }, [steps, isAgeVerificationEnabled, isDigitalSpoofEnabled, isFaceEvasionEnabled]);

  return (
    <Box>
      <Grid container>
        {checkStatus !== LivenessStepStatus.Disabled && (
          <Grid item>
            <Box className={classes.media}>
              <Grid container>
                {checkStatus !== LivenessStepStatus.FewData && steps.map((item, index) => (
                  <React.Fragment key={item.id}>
                    {/* video */}
                    {item?.videoUrl !== MediaStatusTypes.MediaIsEmpty && (
                      <Grid
                        container
                        item
                        xs={12}
                        xl={steps.length === 2 ? 4 : 6}
                        key={index}
                        className={classes.mediaItem}
                      >
                        <Grid item xs={12}>
                          <LivenessMedia
                            video={item.videoUrl}
                            title={intl.formatMessage({ id: `LivenessStep.Checks.${item.id}.title` })}
                            withSoundButton={item.id === StepTypes.Voice}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </React.Fragment>
                ))}
                <Grid item xs={12} xl={steps.length === 2 ? 4 : 6} className={classes.mediaItem}>
                  {/* image */}
                  {steps[0]?.selfieUrl && (
                    <LivenessMedia
                      image={steps[0].selfieUrl}
                      title={intl.formatMessage({ id: 'LivenessStep.Checks.selfie.title' })}
                      subtitle={steps[0]?.videoUrl !== MediaStatusTypes.MediaIsEmpty && intl.formatMessage({ id: 'LivenessStep.Checks.selfieExtracted.title' })}
                      downloadableFileName={downloadableFileName}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        )}
        <Grid item>
          <BoxBordered ml={2} p={1}>
            <CheckResultLogo status={checkStatus} type="biometric" />
            <LivenessConslusion steps={steps}>
              {duplicateFaceDetection && <DuplicateSelfieCheck stepData={duplicateFaceDetection} />}
            </LivenessConslusion>
            {(isAgeVerificationEnabled || isDigitalSpoofEnabled || isFaceEvasionEnabled) && (
              <>
                <Box color="common.black75" fontSize={18}>
                  {intl.formatMessage({ id: 'LivenessStep.Checks.Premium.title' })}
                </Box>
                <Box mt={2}>
                  <LivenessPremium steps={premiumCheckSteps} />
                </Box>
              </>
            )}
          </BoxBordered>
        </Grid>
      </Grid>
    </Box>
  );
}
